import satvisSetup from "./app";

const { cc } = satvisSetup({
  sat: {
    enabledTags: ["ONDOSAT"],
  },
});

cc.sats.addFromTleUrls([
  ["data/tle/groups/ondosat.txt", ["ONDOSAT"]],
  ["data/tle/groups/ondosat-2.txt", ["ONDOSAT-2"]],
  ["data/tle/groups/ondosat-3.txt", ["ONDOSAT-3"]],
  ["data/tle/groups/ondosat-172.txt", ["ONDOSAT-172"]],
]);

// cc.sats.addTleUsingUrls([
//   ['https://tle.ivanstanojevic.me/api/tle/59114', ['ONDOSAT-OWL-1']],
//   ['https://tle.ivanstanojevic.me/api/tle/59113', ['ONDOSAT-OWL-2']],
// ])
